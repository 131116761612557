import React from "react";
import { Container,Button, Box, Typography, Avatar, IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SectionTitle from "./SectionTitle";
const CEOMessageShort = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/Mot-du-DG"); // Redirect to specified route
    window.scrollTo({ top: 0, behavior: "smooth" }); // Ensure smooth scroll to top
  };
  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 4,
        p: 3,
      //   backgroundImage: "url('/images/abstract.jpg')", // Add the background image
      //   backgroundSize: "cover",
      //   opacity:90,
      //   backgroundPosition: "bottom",
      //   backgroundRepeat: "no-repeat",
      //   borderRadius: "12px",
      //   boxShadow: "0px 4px 10px rgba(76, 191, 223, 0.2)", // Adding a shadow effect
      }}
    >        <SectionTitle text="Mot du DG"/>

      <Box
        sx={{
          // backgroundImage: "url('/images/abstract.jpg.avif')", // Add the background image

          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 4,
          alignItems: "flex-start",
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Light overlay for readability
          padding: 3,
          borderRadius: "12px",
        }}
      >

<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 400, // max width constraint
    mx: "auto", // center horizontally
    px: 2, // some horizontal padding on mobile
  }}
>
  <Avatar
    src="/images/dg.JPG"
    alt="MAHAMAT OUMAR KESSOU"
    sx={{
      width: { xs: 200, sm: 250, md: 300, lg: 350 }, // responsive sizing
      height: { xs: 200, sm: 250, md: 300, lg: 350 },
      mb: 2,
      borderRadius: "12px",
      border: "2px solid #ddd",
    }}
  />
  <Typography variant="h5" component="div" sx={{ fontWeight: "bold", textAlign: "center" }}>
    MAHAMAT OUMAR KESSOU
  </Typography>
  <Typography variant="h6" color="text.secondary" sx={{ textAlign: "center" }}>
    Directeur Général
  </Typography>
  <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
    <IconButton aria-label="LinkedIn" component="a" href="https://www.linkedin.com/in/">
      <LinkedInIcon />
    </IconButton>
    <IconButton aria-label="Twitter" component="a" href="https://twitter.com/">
      <TwitterIcon />
    </IconButton>
    <IconButton aria-label="Facebook" component="a" href="https://www.facebook.com/">
      <FacebookIcon />
    </IconButton>
  </Box>
</Box>

        
        <Box sx={{ flex: 7 }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
            }}
          >
            <b>Chers citoyens, partenaires et visiteurs,</b>
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
            C’est avec un immense plaisir que je vous souhaite la bienvenue sur le site officiel de l’Agence Nationale des Titres Sécurisés (ANATS). Notre mission consiste à garantir la délivrance de documents officiels sécurisés et fiables, au service des citoyens et des institutions de notre pays.
          </Typography>


          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
Dans un monde où la sécurité et l’authenticité des documents officiels sont primordiales, l’ANATS s’engage à fournir des services modernes, efficaces et accessibles. Créée par l’Ordonnance N°001/PR/2016, notre Agence veille à la protection des identités et à la sécurisation des titres officiels tels que les passeports, les cartes d’identité et les permis de conduire.         
 </Typography>


 <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
Pour atteindre cet objectif, l’Agence a mis en place le Système Intégré de Gestion des Populations et des Titres Sécurisés (SIGPTS). Ce dispositif permet, après l’enrôlement et la déduplication, d’attribuer à chaque individu (citoyen, réfugié, étranger résident ou en transit sur le territoire national) un Numéro National d’Identification (NNI) unique et infalsifiable, généré de manière aléatoire.  </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
            <Button
      onClick={handleClick}
      variant="contained"
      sx={{
        background: "linear-gradient(45deg, #1e88e5, #42a5f5)",
        color: "white",
        fontSize: "18px",
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: "10px",
        px: 4,
        py: 1.5,
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          background: "linear-gradient(45deg, #1565c0, #1e88e5)",
          transform: "scale(1.05)",
        },
      }}
    >
      Lire Plus
    </Button>
           </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default CEOMessageShort;
