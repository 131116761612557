import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { LocalTaxi,AppRegistration, NaturePeople, SdCard,StickyNote2, DriveEta } from '@mui/icons-material';
import SectionTitle from './SectionTitle';

const results = [{
    title: 'Carte  d\'Identité Nationale',
    icon: <SdCard fontSize='lg' />,
  },
  {
    title: 'Passeport',
    icon: <StickyNote2 fontSize='lg' />,
  },
  {
    title: 'Carte de Résident',
    icon: <NaturePeople fontSize='lg' />,
  },
  {
    title: 'Permis de Conduire',
    icon: <LocalTaxi  fontSize='lg'/>,
  },
  {
    title: 'Carte Grise',
    icon: <DriveEta fontSize='lg' />,
  },
  {
    title: 'Actes d\'État Civil',
    icon: <AppRegistration  fontSize='lg' />,
  },
];

const Results = () => {
  return (
   <>

    <div 
      style={{ 
        padding: '20px', 
        textAlign: 'center', 
        marginTop: '4rem', 
        backgroundImage: "url('/images/Banners_bg03.jpg')", 
        backgroundRepeat: "no-repeat", 
        backgroundSize: "cover", 
        backgroundPosition: "bottom", 
        backgroundAttachment: "fixed" ,
        backgroundColor: "rgba(60, 150, 90, 0.24)",
       }}
    >
               <SectionTitle text="Documents délivrés" color='#fff' />  

      <Typography  color='#474B8F' variant="h4" gutterBottom sx={{ fontWeight: "bold", mb: 3 }}>
      </Typography>
      <Grid 
      mb={8}
        container 
        spacing={4} 
        justifyContent="center" 
       >
        {results.map((result, index) => (
          <Grid sx={{ color: '#1976d2', marginBottom: 4 }} item xs={12} sm={6} md={4} key={index}>
          
        
            <Card 
              
              sx={{ 
                textAlign: 'center', 
                overflow: "hidden",
                border: "2px dashed rgba(200, 181, 181, 0.24)",
                backgroundColor:"rgba(255, 255, 255, 0.29)",
                opacity:"20px",
                color:"#fff",
                padding: '20px', 
                height: '100%', 
                transition: 'transform 0.3s ease-in-out',
                '&:hover': { transform: 'scale(1.07)', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }
              }}
            >
              <Box sx={{ fontSize: 60, color: '#fff', marginBottom: 1 }}>
                {result.icon}
              </Box>
              <CardContent>
                <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
                  {result.title}
                </Typography>
                {/* <Typography fontSize={18} variant="body1" color="text.secondary">
                  {result.number}
                </Typography> */}
              </CardContent>  
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
    </>
  );
};

export default Results;
