import React from "react";
import { useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const slides = [
  { image: "/images/page-01.jpg" },
  { image: "/images/page-02.jpg" },
  { image: "/images/page-03.jpg" },
];

const CarouselComponent = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: isMobile ? "28vh" : "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
        
      }}
    >
      <Swiper
        direction={isMobile ? "horizontal" : "vertical"}
        pagination={{ clickable: true }}
        navigation={true}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"


        
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              height: "100%",
              width: "100%",

    
            }}
          >
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`}
              style={{
                maxWidth: "100%",
                maxHeight: isMobile ? "100%" : "100vh",
                width: isMobile ? "100%" : "100%",
                height: isMobile ? "auto" : "100%",
                objectFit: isMobile ? "contain" : "cover",
                borderRadius: isMobile ? "0px" : "0",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarouselComponent;